"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_use_query_param_string_1 = require("react-use-query-param-string");
const RootProvider = ({ children }) => {
    const [campaign, _setCampaignValue, campaignInitialized] = (0, react_use_query_param_string_1.useQueryParamString)('campaign', '');
    const [utmSource, _setUtmSource, utmSourceInitialized] = (0, react_use_query_param_string_1.useQueryParamString)('utm_source', '');
    const [utmMedium, _setUtmMedium, utmMediumInitialized] = (0, react_use_query_param_string_1.useQueryParamString)('utm_medium', '');
    const [utmCampaign, _setUtmCampaign, utmCampaignInitialized] = (0, react_use_query_param_string_1.useQueryParamString)('utm_campaign', '');
    const [utmTerm, _setUtmTerm, utmTermInitialized] = (0, react_use_query_param_string_1.useQueryParamString)('utm_term', '');
    const [utmContent, _setUtmContent, utmContentInitialized] = (0, react_use_query_param_string_1.useQueryParamString)('utm_content', '');
    (0, react_1.useEffect)(() => {
        if (campaign !== '' && campaignInitialized) {
            sessionStorage.setItem('campaign', campaign);
        }
        if (utmSource !== '' && utmSourceInitialized) {
            sessionStorage.setItem('utm_source', utmSource);
        }
        if (utmMedium !== '' && utmMediumInitialized) {
            sessionStorage.setItem('utm_medium', utmMedium);
        }
        if (utmCampaign !== '' && utmCampaignInitialized) {
            sessionStorage.setItem('utm_campaign', utmCampaign);
        }
        if (utmTerm !== '' && utmTermInitialized) {
            sessionStorage.setItem('utm_term', utmTerm);
        }
        if (utmContent !== '' && utmContentInitialized) {
            sessionStorage.setItem('utm_content', utmContent);
        }
    }, [campaign, utmSource, utmMedium, utmCampaign, utmTerm, utmContent]);
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
};
exports.RootProvider = RootProvider;
