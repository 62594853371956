"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wrapRootElement = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./src/styles/ress.css");
require("./src/styles/font.css");
require("./src/styles/global.css");
const RootProvider_1 = require("./src/components/RootProvider");
const wrapRootElement = ({ element }) => {
    return (0, jsx_runtime_1.jsx)(RootProvider_1.RootProvider, { children: element });
};
exports.wrapRootElement = wrapRootElement;
